.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /*min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);*/
  color: white;
}

.themed-container {
  margin: 3em auto;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: block;
  position: fixed;
  z-index: 1031; /* High z-index so it is on top of the page */
  top: calc( 50% - ( 60px / 2) ); /* where ... is the element's height */
  right: calc( 50% - ( 60px / 2) ); /* where ... is the element's width */
}
